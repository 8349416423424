<template>
  <div>
    <div class="controls-panel">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-6 col-md-3 pe-4 ps-3">
              <div class="row">
                <div class="col-3">
                  <button class="btn btn-control p-0"
                          content="Select"
                          v-tippy="{ placement : 'bottom', arrow : false, arrowType : 'round' }">
                    <div class="form-group">
                      <input type="checkbox" name=""
                             class="form-check-input m-0"
                             @click="selectAll(selectAllCheckbox)"
                             v-model="selectAllCheckbox">
                    </div>
                  </button>
                </div>
                <div class="col-3">
                  <button class="btn btn-control"
                          @click="deleteSelected"
                          content="Delete"
                          v-tippy="{ placement : 'bottom', arrow : false, arrowType : 'round' }">
                    <img src="@/assets/icons/bold-icon delete.svg" alt="Delete">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-end mt-3 mt-md-0">
          <div class="d-flex align-items-center justify-content-end">
            <span class="color-light-grey border-right-light pe-3 me-3 selected-count">
              {{ selectedCount }} selected
            </span>
            <pagination
              class="justify-content-end"
              :from="10"
              :to="20"
              :total="300"
              :page="1"
              :per-page="2" />
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="row mt-2" v-if="ui.loading === false">
        <div class="col-12 col-md-6">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search" v-model="searchField">
          </div>
        </div>
      </div>
      <div class="table" v-if="ui.loading === false">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>Bookkeeper</th>
              <th>Agent</th>
              <th>Loan Number	</th>
              <th>Amount</th>
              <th>Category</th>
              <th>Date</th>
              <th>Range</th>
              <th>Memo</th>
              <th>Vendor Name</th>
              <th>Status</th>
              <th>Invoice</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(expense, index) in expenseProcessed" :key="index">
              <td>
                <div class="form-group">
                  <input type="checkbox" name=""
                         @click="countSelected(expense.checked); expense.checked = !expense.checked"
                         v-model="expense.checked"
                         id="" class="form-check-input m-0" >
                </div>
              </td>
              <td>Ryan Nohalty</td>
              <td>Ryan Nohalty</td>
              <td>N/A</td>
              <td>$0.00</td>
              <td>N/A</td>
              <td>November 17, 2020 09:21</td>
              <td>Nov, 11 2020 - Nov, 11 2020</td>
              <td>Lendingtree Fee</td>
              <td>LendingTree</td>
              <td>
                <div class="status d-flex justify-content-center verified">
                  verified
                </div>
              </td>
              <td>
                <button class="btn btn-primary">
                  Download File
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination";

export default {
  name: 'ExpenseProcessed',
  components: {Pagination},
  data() {
    return {
      searchField: '',
      selectAllCheckbox: false,
      editAllow: false,
      selectedCount: 0,
      expenseProcessed: [],
      ui: {
        loading: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.expenseProcessed = [{id: 1, bookkeeper: 'Brooke Allen'}, {id: 2, bookkeeper: 'Brooke Allen'}];
        this.ui.loading = false;
      }, 1000)
    },
    selectAll(val) {
      if (val === false) {
        this.expenseProcessed.map((expense) => {
          expense.checked = true;
          this.selectedCount++;
        })
      } else {
        this.expenseProcessed.map((expense) => {
          expense.checked = false;
          this.selectedCount--;
        })
      }
    },
    countSelected(val) {
      if (val === false) {
        this.selectedCount++;
        this.selectAllCheckbox = true;
        if (this.selectedCount === 1) {
          this.editAllow = true;
        }
      } else {
        this.selectedCount--;
        this.editAllow = false;
        if (this.selectedCount === 0) {
          this.selectAllCheckbox = false;
        }
      }
    },
    deleteSelected() {
      const deleteRequests = this.expenseProcessed.filter((request) => {
        return request.checked === true
      });

      if (deleteRequests.length > 0) {
        this.ui.loading = true;
        setTimeout(() => {
          this.ui.loading = false;
          this.selectAllCheckbox = false;
        }, 1000);
      } else {
        alert('Pls select')
      }
    }
  },
  beforeMount() {
    this.getData()
  },
  computed: {
    page() {
      return this.$route.name
    },
  }
}
</script>

<style lang="scss" scoped>
table.table {
  min-width: 1600px;
  tr {
    vertical-align: middle;
  }
  .status {
    max-width: 95px;
    width: 100%;
    padding: 5px 19px;
    border-radius: 8px;
    &.unverified {
      border: 1px solid rgba(0, 7, 46, 0.2);
      background-color: rgba(0, 7, 46, 0.05);
      color: black;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
    }
    &.verified {
      border: 1px solid rgba(38,111,229,0.4);
      border-radius: 8px;
      background-color: rgba(38,111,229,0.05);
      color: rgba(38,111,229,1);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
}
</style>
